<template>
	<div class="plan" v-if="plans.length">
		<div class="price_info">
			<span class="price">
				<span class="icon">￥</span>
				<span class="integer">{{ priceInt }}</span>
				<span class="float">.{{ priceFloat }}</span>
				<span class="price_suffix" v-if="priceSuffix" v-text="priceSuffix"></span>
			</span>
			<span class="sales">{{ $t('salesVolume') }}：{{ proInfo.popularity }}</span>
		</div>
		<!-- 保险名称 -->
		<div class="product">
			<span class="name main_title" v-text="proInfo.proName">“耀之冠军”综合赛事保障</span>
		</div>
		<div class="product_info">
			<span>保障年龄：</span>
			<span v-text="protectAge"></span>
			<span class="line"></span>
			<span>保障期限：</span>
			<span v-text="proInfo.viewInsureTime"></span>
			<span v-if="false" class="icon arrow_top"></span>
		</div>
		<!-- 广告语 -->
		<div v-if="proInfo.productAdv" class="product_tags">{{ proInfo.productAdv }}</div>
		<!-- 保障计划 -->
		<div class="card_box">
			<div class="plain main_title"><div class="line_active"></div>保障计划</div>
			<div class="plain_cont">
				<ly-tab v-model="selectedIndex" :items="plans" :options="options"> </ly-tab>
			</div>
				<!-- 保障责任 -->
			<div >
				<div class="btn" v-if="false">
					<span class="btn_text">展开</span>
					<span class="arrow_down_info"></span>
				</div>
			</div>
			<ul class="duty_list">
				<van-cell-group :border="false">
					<template v-for="(item, index) in dutyList">
						<van-cell :key="index" v-if="item.price" :title="item.contentName" :value="item.price" @click="showDutyDetail(item)" title-class="cell_title" value-class="cell_value" :border="false" is-link />
					</template>
				</van-cell-group>
				<li class="see_more_line" v-if="!dutyShowAll&&dutyList.length"></li>
				<li class="see_more" v-show="!dutyShowAll&&dutyList.length">
					<span class="text" @click="dutyShowAll = true">查看更多</span>
					<span class="arrow_right"></span>
				</li>
				<li class="line"></li>
			</ul>
			<div class="more_detail">
				更多详情，请阅读
				<span class="link" @click="showInsuranceInformation">《投保须知》</span>
				和
				<span class="link" @click="openProvisionPage">《投保条款》</span>
			</div>
		</div>
		<!-- 信息弹出层 -->
		<bottom-info-pop v-model="infoPop" :title="infoPopData.title" :content="infoPopData.text"></bottom-info-pop>
	</div>
</template>

<script>
import { Cell, CellGroup, ActionSheet, DropdownMenu, DropdownItem } from 'vant';

export default {
	components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[ActionSheet.name]: ActionSheet,
		[DropdownMenu.name]: DropdownMenu,
		[DropdownItem.name]: DropdownItem,
	},
	props: {
		// 计划数组
		plans: {
			type: Array,
			required: true,
		},

		proInfo: {
			type: Object,
			required: true,
		},

		// 金额后缀
		priceSuffix: {
			type: String,
			reuqired: false,
		},
		price: {
			type: [String, Number],
			required: true,
		},
		// 保障年龄格式化
		ageFormatter: {
			type: Function,
			required: false,
			default: function (ageStr) {
				return ageStr;
			},
		},
		// 投保须知
		hint: {
			type: String,
			default: '',
		},
		comboId: {
			type: [Number, String],
		},
	},
	data() {
		return {
			selectedIndex: null,
			options: {
				activeColor: '#1d98bd',
				labelKey: 'comboName',
				// 可在这里指定labelKey为你数据里文字对应的字段名
			},
			dutyShowAll: false,
			// 底部信息弹窗层
			infoPop: false,
			infoPopData: {
				title: '',
				text: '',
			},
		};
	},
	computed: {
		priceInt() {
			let result = '0';
			let fullPrice = this.$base.floatPrice(this.price);
			if (fullPrice) {
				result = fullPrice.split('.')[0];
			}
			return result;
		},
		priceFloat() {
			let result = '00';
			let fullPrice = this.$base.floatPrice(this.price);
			if (fullPrice) {
				result = fullPrice.split('.')[1];
			}
			return result;
		},
		// 保障年龄
		protectAge() {
			return this.ageFormatter(this.proInfo.insureAge);
		},
		// 保障责任
		dutyList() {
			let result = [];
			if (this.plans.length) {
				if (!this.dutyShowAll) {
					result = this.plans[this.selectedIndex].contentList.slice(0, 3);
				} else {
					result = this.plans[this.selectedIndex].contentList;
				}
			}
			return result;
		},
	},
	watch: {
		selectedIndex: {
			handler() {
				this.planChange();
			},
			immedate: true,
		},
	},
	created() {
		this.selectedIndex = 0;
	},
	methods: {
		planChange() {
			this.$emit('planChange', this.selectedIndex);
		},
		// 查看保障责任详情
		showDutyDetail(detail) {
			this.showInfoPop(detail.contentName, detail.dutyInstruction);
		},
		// 显示底部弹出层
		showInfoPop(title, contentText) {
			this.infoPopData = {
				title: title,
				text: contentText,
			};
			this.infoPop = true;
		},
		// 投保须知
		showInsuranceInformation() {
			this.showInfoPop('投保须知', this.hint ? this.hint : this.proInfo.hint);
		},
		// 打开投保条款页面
		openProvisionPage() {
			this.$router.push({
				name: 'safeguardClause',
				params: {
					productId: this.proInfo.proId,
					comboId: this.comboId,
				},
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.cell_title,
.cell_value {
	color: $font_color_thd;
	font-size: 0.24rem;
}

.main_title {
	font-size: 0.3rem;
	color: $font_color_main;
	font-weight: bold;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.sec_title {
	font-size: 0.28rem;
	color: $font_color_main;
	font-weight: bold;
}
.banner {
	margin-top: 0.2rem;
	display: flex;
	justify-content: center;
	img {
		width: 6.9rem;
		height: 3.91rem;
	}
}
.price_info {
	padding: 0.2rem 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.price {
		color: $color_active;
		display: flex;
		align-items: baseline;
		.integer {
			font-size: 0.56rem;
			font-weight: bold;
			line-height: 0.42rem;
		}
		.icon {
			font-size: 0.3rem;
		}
		.price_suffix,
		.float {
			font-size: 0.36rem;
		}
		.price_suffix {
			margin-left: 0.04rem;
		}
	}
	.sales {
		color: $font_color_info;
		font-size: 0.24rem;
	}
}

.product {
	padding-bottom:  0.2rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	img {
		height: 0.25rem;
	}
}

.product_info {
	padding-bottom:  0.2rem;
	font-size: 0.24rem;
	color: $font_color_main;
	display: flex;
	align-items: center;
	.line {
		width: 1px;
		height: 0.25rem;
		background-color: $font_color_main;
		margin: 0 0.16rem;
	}
	.icon {
		margin-left: 0.16rem;
	}
}

.product_tags {
	display: inline-block;
	text-align: center;
	color: $color_active;
	background-color: rgba($color: $color_active, $alpha: 0.1);
	font-size: 0.2rem;
	margin-bottom: 0.2rem;
}

.plain{
	border-bottom:0.02rem solid $b_border_color ;
	padding-bottom: 0.2rem;
}

.plain_cont {
	padding: 0.2rem ;
}

.duty {
	padding: 0.14rem 0.3rem;
	margin-top: 0.16rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.btn {
		font-size: 0.24rem;
		color: $font_color_main;
		.btn_text {
			margin-right: 0.1rem;
		}
	}
}
.duty_list {
	// padding: 0 .3rem;
	position: relative;
	li {
		padding: 0.24rem 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: $font_color_main;
		font-size: 0.24rem;
		// line-height: .24rem;
		.money_box {
			display: flex;
			align-items: center;
		}
		.money {
			margin-right: 0.1rem;
		}
	}
	.see_more_line{
		position: absolute;
		bottom: 1.2rem;
		left: 0;
		height: 0.6rem;
		width: 100%;
		background: linear-gradient( 180deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
	}
	.see_more {
		margin: 0.12rem 0 0.06rem 0;
		background: linear-gradient( 180deg, rgba(255,255,255,0) 0%, #FFFFFF 80%);
		justify-content: center;
		
		.text {
			margin-right: 0.1rem;
		}
	}
	.line {
		padding: 0;
		height: 1px;
		background-color: #ddd;
	}
	::v-deep(.van-cell){
		padding: 0.2rem 0;
		border-bottom:0.02rem solid $b_border_color;
		font-size: 0.28rem;
		&:last-child{
			border-bottom: 0;
		}
	}
}

.count {
	margin: 0.39rem 0.3rem 0.31rem;
	height: 0.8rem;
	border-radius: 8px;
	font-size: 0.3rem;
	text-align: center;
	color: #fff;
	background-color: $color_main;
	line-height: 0.8rem;
}

.more_detail {
	margin-top: 0.2rem;
	padding: 0 0.3rem;
	font-size: 0.24rem;
	color: $font_color_info;
	.link {
		color: $color_main;
	}
}

.tab {
	padding: 0.2rem 0.3rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 0;
	background-color: #fff;
	li {
		text-align: center;
	}
	.text {
		font-size: 0.3rem;
		font-weight: bold;
	}
	span {
		display: inline-block;
		width: 0.3rem;
		height: 0.06rem;
		background-color: #fff;
		margin-top: 0.14rem;
	}
	.active .text {
		color: $color_main;
	}
	.active span {
		background-color: $color_main;
	}
}
.tab_float {
	position: fixed;
	top: 0.88rem;
	left: 0;
	width: 100%;
	box-sizing: border-box;
	box-shadow: 0 0 4px #ddd;
}
.tab_content {
	padding: 0.2rem 0.3rem 0.6rem;
	box-sizing: border-box;
	.banner {
		width: 100%;
		margin-bottom: 0.8rem;
	}
	.guide {
		.main_title {
			line-height: 0.3rem;
		}
		.sec_title {
			line-height: 0.28rem;
			margin-top: 0.3rem;
			margin-bottom: 0.2rem;
		}
		p {
			color: $font_color_sec;
			font-size: 0.24rem;
			line-height: 0.36rem;
			margin-bottom: 0.63rem;
		}
	}
	.question {
		padding-top: 0.32rem;
		.main_title {
			padding-bottom: 0.09rem;
			line-height: 0.3rem;
		}
		.cont {
			padding-top: 0.34rem;
		}
		.cont_line {
			border-top: 1px solid #dddddd;
		}
		.que,
		.ans {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
		}
		.icon {
			flex-shrink: 0;
			margin-right: 0.16rem;
		}
		.text {
			font-size: 0.24rem;
			line-height: 0.3rem;
			color: $font_color_main;
		}
		.ans {
			margin-top: 0.24rem;
			margin-bottom: 0.24rem;
			.text {
				color: $font_color_sec;
			}
		}
		.more_ans {
			font-size: 0;
			margin-bottom: 0.3rem;
			margin-left: 0.46rem;
			display: flex;
			align-items: center;
			.text {
				font-size: 0.24rem;
				color: $color_active_3;
				line-height: 0.24rem;
				margin-right: 0.1rem;
			}
		}
	}
	.example {
		margin-top: 0.8rem;
		p {
			font-size: 0.24rem;
			color: $font_color_main;
			line-height: 0.36rem;
			margin-top: 0.4rem;
			margin-bottom: 0.57rem;
			text-indent: 2em;
		}
	}
	.see_more {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0;
		span {
			font-size: 0.24rem;
			color: $font_color_main;
			line-height: 0.24rem;
		}
		.icon {
			margin-left: 0.1rem;
		}
	}
}

::v-deep .product_advantage {
	margin-bottom: 0.8rem;
	font-size: 12px;
	img {
		max-width: 100%;
	}
}

::v-deep .guide_content_box {
	margin-top: 0.3rem;
	img {
		max-width: 100%;
	}
}


::v-deep .age_box {
	position: relative;
	right: 6px;
	.van-dropdown-menu__bar {
		box-shadow: none;
	}
	.van-ellipsis {
		font-size: 0.28rem;
	}
}
.age_cell_title {
	display: flex;
	align-items: center;
	font-size: 0.28rem;
	color: #333;
}
.calculateForm_value {
	color: #333;
}
.calculateForm_content {
	padding-bottom: 1.1rem;
}
.calculateForm_product_name {
	font-size: 0.32rem;
	font-weight: bold;
}

.enter_process_pop {
	z-index: 3001 !important;
	.content {
		font-size: 0.24rem;
		color: $font_color_main;
		padding: 0.2rem 0.3rem;
	}
	.link {
		color: $color_main;
	}
	.section {
		padding: 0.2rem 0;
		text-indent: 2em;
		line-height: 0.4rem;
	}
	.footer {
		text-indent: 2em;
	}
}
.about {
	color: $font_color_main;
	font-size: 0;
	text-align: center;
	padding: 0.3rem 0.3rem 0.8rem;
	.header {
		font-size: 0.3rem;
		line-height: 0.48rem;
		font-weight: bold;
	}
	.title {
		font-size: 0.3rem;
		line-height: 0.4rem;
		margin-top: 0.2rem;
		margin-bottom: 0.1rem;
	}
	.section {
		font-size: 0.24rem;
	}
	ul {
		margin-top: 0.6rem;
		display: flex;
		align-items: center;
		justify-content: space-around;
		font-size: 0.2rem;
		li {
			display: flex;
			align-items: center;
			.icon {
				font-size: 0.6rem;
				color: $font_color_info;
			}
			.text {
				font-size: 0.16rem;
				margin-left: 0.1rem;
				p {
					line-height: 0.26rem;
				}
			}
		}
	}
}

::v-deep .calculateForm_content {
	.van-dropdown-item__content {
		max-height: 30vh;
	}
}

.line_box {
	padding: 0.5rem 0;
	.line {
		height: 1px;
		background-color: #ddd;
	}
}

.weChat_warning {
	font-size: 0.4rem;
	text-align: center;
}

::v-deep .ly-tab-item {
	align-items: flex-start !important;
}

.nav_menu {
	float: right;
	font-size: 0.3rem;
	margin-left: -2rem;
}
.card_box{
	background: #FFFFFF;
	box-shadow: 0rem 0.04rem 0.16rem 0rem rgba(0,0,0,0.2);
	border-radius: 0.12rem;
	margin-top: 0.24rem;
	padding: 0.2rem;
}
.line_active{
	width: 0.08rem;
	height: 0.32rem;
	background: $color_active_5;
	margin-right: 0.2rem;
}

::v-deep .ly-tab-item {
	align-items: flex-start !important;
	&:first-child{
		margin-left: 0;
	}
}

</style>
