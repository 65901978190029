<template>
	<div class="body">
		<div class="weChat_warning" v-if="weChatWarningShow">
			<h4>请在微信或支付宝中打开</h4>
		</div>
		<div v-else>
			<top-nav v-if="!isCPS" :showBack="isCPS" @back="back">{{ insProduct2Vo.proName }}</top-nav>
			<top-nav v-else bgColor="#F6F6FB" backImage :showBack="isCPS" @back="back"><span class="title_name">所有产品</span></top-nav>

			<header>
				<img v-if="insProduct2Vo.proPicUrl" :src="insProduct2Vo.proPicUrl" alt="banner" />
			</header>
			<!-- 计划 -->
			<productPlanV2 v-if="planList.length" :plans="planList" :comboId="comboId" :proInfo="this.insProduct2Vo" priceSuffix="起" :price="money" :hint="hint" @planChange="planChange" />
			<!-- 产品解读 -->
			<product-narrate :information="productIntroduction" :questionList="questionList" />
			<!-- 购买按钮 -->
			<!-- <price-buy-btn :price="money" @buy="buy" :isword="true"></price-buy-btn> -->
			<div class="price_buy_btn">
				<div class="cont">
					<div class="price">
						<div class="price_content">
							<span class="text">价格</span>
							<span class="icon">￥</span>
							<span class="num">{{ money }}</span>
						</div>
					</div>
					<div class="btn" @click="buy">
						<slot>立即购买</slot>
					</div>
				</div>
				<div class="back_dom"></div>
			</div>

			<!-- 确定进入投保流程 -->
			<enter-sure v-model="enterWarning" :name="insurerFullName" :isLink="false" :fileUrl="notificationLink" @confirm="agreeEnter" :proId="this.proId" @cancel="stopKhs" @clickOverlay="stopKhs">
				<template #content>
					<span class="link" @click="handlePop">客户告知书</span>
				</template>
			</enter-sure>
		</div>
		<!-- 告知书 -->
		<bottom-info-pop v-model="infoPop" :title="infoPopData.title" :content="infoPopData.text"></bottom-info-pop>
	</div>
</template>

<script>
import user from '@/components/user.js';
import productNarrate from '@/components/cpsInsureProductNarrate.vue';
import { http_getProductConfig, http_getProductComboConfig, http_getProductCorrelation } from '@/request/insureV2.js';
import productPlanV2 from './components/productPlanV2.vue';
import enterSure from '@/components/enterSure.vue';

export default {
	name: 'liabilityIndexV2',
	components: {
		productPlanV2,
		productNarrate,
		enterSure,
	},

	data() {
		return {
			weChatWarningShow: false,
			insProduct2Vo: {}, //商品信息
			planList: [], //计划
			insProductPrice2VoList: [], //价格
			hint: '', //投保须知
			productIntroduction: {}, //产品解读和指南
			questionList: [], //问题解读
			enterWarning: false,
			insurerFullName: '', // 保险公司名称
			notificationLink: '', // 告知书文档链接
			insProductInformation2Vo: {},
			comboId: '',
			infoPop: false,
			infoPopData: {
				title: '',
				text: '',
			},
			productCorrelationList: null, //关联产品列表
			selComboIdPriceList: [], //关联产品的priceList
			thirdPartyCallbackParam: '', //第三方回调参数
			returnUrl: '', //支付成功回调
			money:0,
			insProductComboList:[]
		};
	},
	props: {
		platform: {
			type: String,
			default: 'H5',
		},
	},

	created() {
		this.init();
	},
	computed: {
		isCPS() {
			return this.platform === 'CPS';
		},
	},
	mixins: [user],
	methods: {
		// 停止可回溯
		stopKhs() {
			khs.stop();
		},
		// 初始化
		init() {
			if (!this.$base.isDev() || this.$base.getEnv()) {
				this.$base.saveUrl();
				this.getQuery();
				if (this.proId) {
					// 如果是cps不用登录
					if (this.platform === 'CPS') {
						this.userId = JSON.parse(localStorage.getItem('cps_brindInfo')).userId;
						//调用接口判断是否有多产配置
						this.getProductCorrelation();
						this.getProduct();
					
					} else {
						this.mixin_login().then(userInfo => {
							this.userId = userInfo.id;
							//调用接口判断是否有多产配置
							this.getProductCorrelation();
							this.getProduct();

						});
					}
				}
			} else {
				this.weChatWarningShow = true;
			}
		},
		// 获取地址中的参数
		getQuery() {
			let query = this.$route.query;
			if (query && Object.hasOwnProperty.call(query, 'product')) {
				this.proId = query.product;
				this.thirdPartyCallbackParam = query.thirdPartyCallbackParam;
				this.returnUrl = query.returnUrl;

			}
		},

		//是否有多产品配置
		async getProductCorrelation() {
			const productCorrelation = await http_getProductCorrelation(this.proId);
			if (productCorrelation.length > 0) {
				const obj = {};
				productCorrelation.forEach(item => {
					item.comboIdList = item.comboIdList.map(v => {
						return { ...v, targetProId: item.targetProId };
					});
					if (obj[item.sourceProId]) {
						obj[item.sourceProId].push(item);
					} else {
						obj[item.sourceProId] = [item];
					}
				});
				this.productCorrelationList = obj;
			}
		},

		back() {
			this.$router.go(-1);
		},
		// 获取详情
		async getProduct() {
			const res = await http_getProductConfig({ proId: this.proId });
			this.insProduct2Vo = res.insProduct2Vo;
			this.planList = res.comboList;
			this.insProductInformation2Vo = res.insProductInformation2Vo;
			this.productIntroduction = { productCase: res.insProductInformation2Vo.productCase, compensationHint: res.insProductInformation2Vo.compensationHint, productIntroduce: res.insProductInformation2Vo.productIntroduce };
			this.questionList = res.insProductInformation2Vo.insProductClause2VoList;
			this.insurerFullName = res.insurer2Vo.fullName;
			this.insProductComboList=res.insProductComboList

		},
		// 获取计划信息
		async planChange(selectedIndex) {
			this.comboId = this.planList[selectedIndex].comboId;
			this.money=this.insProductComboList[selectedIndex].viewPrice
			const comboInfo = await http_getProductComboConfig({ proId: this.proId, comboId: this.comboId });
			this.insProductPrice2VoList = comboInfo.insProductPrice2VoList;
			this.hint = comboInfo.hint;
		},
		// 购买
		buy() {
			khs.start(this.userId);
			this.enterWarning = true;
		},
		// 同意进入投保流程
		agreeEnter() {
			let routerName = 'liabilityCreateOrderV2';
			if (this.platform === 'CPS') {
				routerName = 'cpsLiOrderV2';
			}
			this.$router.push({
				name: routerName,
				params: {
					proId: this.proId,
					comboId: this.comboId,
					uid: this.userId,
					platform: this.platform,
					productCorrelationList: this.productCorrelationList,
					thirdPartyCallbackParam: this.thirdPartyCallbackParam,
					returnUrl: this.returnUrl,
				},
			});
		},
		// 客户告知书
		handlePop() {
			if (!this.insProductInformation2Vo.f4FixedInfo) return;
			this.infoPopData = {
				title: '客户告知书',
				text: this.insProductInformation2Vo.f4FixedInfo,
			};
			this.infoPop = true;
		},
	},
};
</script>

<style lang="scss" scoped>
.body {
	font-size: 0.28rem;
	padding: 0.2rem 0.4rem;
}
header {
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	img {
		width: 100%;
	}
}
.p1 {
	padding: 0.1rem;
}
.link {
	color: $color_main;
}
.title_name{
	color: $font_color_main;
}

.price_buy_btn {
	.cont {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 0.8rem;
		display: flex;
		align-items: center;
		border-top: 1px solid #eeeeee;
		font-size: 0;
		z-index: 3000;

		.price {
			background-color: #fff;
			width: 3rem;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $color_active_4;

			.text {
				font-size: 0.24rem;
				line-height: 0.24rem;
				color: $color_active_4;
				margin-right: 0.08rem;
			}

			.icon {
				font-size: 0.24rem;
				color: $color_active_4;
			}

			.num {
				font-size: 0.36rem;
				color:$color_active_4;
				line-height: 0.4rem;
			}

			.price_content {
				display: flex;
				align-items: baseline;
			}
		}

		.btn {
			height: 100%;
			flex: 1;
			font-size: 0.28rem;
			line-height: 0.8rem;
			text-align: center;
			color: #fff;
			background-color: $color_active_4;
		}
	}

	.back_dom {
		height: 100%;
		height: 1.1rem;
	}
}
</style>
